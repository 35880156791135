<template>
  <div>
    <van-nav-bar
      :title="$t('cancelAccount.title')"
      fixed
      placeholder
    >
      <template #left>
        <van-icon
          :name="$icon('back')"
          size="16"
          color="#000"
          is-link
          @click="$router.back()"
        />
      </template>
    </van-nav-bar>
    <section class="container">
      <h1>{{ $t('cancelAccount.heading') }}</h1>
      <ul>
        <li>{{ $t('cancelAccount.cautions.1') }}</li>
        <li>{{ $t('cancelAccount.cautions.2') }}</li>
        <li>{{ $t('cancelAccount.cautions.3') }}</li>
      </ul>
      <div class="delete__confirmation">
        <van-button
          type="primary"
          native-type="submit"
          block
          round
          @click="deleteAccount = true"
        >
          {{ $t('cancelAccount.confirm') }}
        </van-button>
      </div>
    </section>
    <van-popup
      v-model="deleteAccount"
      position="bottom"
    >
      <div class="container delete__actions">
        <p>{{ $t('cancelAccount.confident') }}</p>
        <van-button
          type="default"
          native-type="submit"
          block
          plain
          round
          @click="deleteAccount = false"
        >
          {{ $t('cancel') }}
        </van-button>
        <van-button
          type="danger"
          native-type="submit"
          block
          round
          @click="cancelAccount()"
        >
          {{ $t('cancelAccount.continue') }}
        </van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>

import { Notify } from 'vant';
import firebase from 'firebase/app';

export default {
  data() {
    return {
      deleteAccount: false,
    };
  },
  methods: {
    cancelAccount() {
      const user = firebase.auth().currentUser;
      this.deleteAccount = false;
      user.delete().then(() => {
        Notify({
          type: 'primary',
          message: this.$i18n.t('cancelAccount.deleteSuccess'),
        });
      }).catch((error) => {
        if (error.code === 'auth/requires-recent-login') {
          Notify({
            type: 'primary',
            message: this.$i18n.t('cancelAccount.loginRequired'),
          });
          setTimeout(() => {
            this.$router.push({ name: 'logout' });
          }, 4000);
        }
      });
    },
  },
};

</script>
<style lang="scss" scoped>
@use '~@/styles/config' as config;
h1 {
  font-size: config.$font-size-lg;
}
ul {
  list-style: circle;
  padding-left: 1rem;
  li {
    margin-bottom: config.$spacing-xxs;
  }
}
.delete__confirmation {
  margin-top: 2rem;
}
.delete__actions {
  margin: 1.5rem 0;
}
button {
  margin-top: 1rem;
}
</style>
